module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5Q7HJWB","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","category":"website"}},"gtmAuth":"imWtj3TafT_BVI_zmV-l8w","gtmPreview":"env-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/rentlio-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bf09c79fca8fe8bf1a29b86daa071f96"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"905e10eb30b9c9c74ff67f7ef005209e","enableOnDevMode":true,"mixpanelConfig":null,"pageViews":"all","trackPageViewsAs":"page_viewed","getPageViewTransformerFn":"return (location) => {\n          return {\n            url: location.pathname,\n            category: 'website'\n          }\n        };"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
