/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./src/styles/fonts.css')
const gaHelper = require('./src/helper/ga.js');

exports.onClientEntry = () => {
    if (typeof window.IntersectionObserver === `undefined`) {
        import(`intersection-observer`)
    }

    window.uid = gaHelper.getUID();
    if (window.dataLayer) {
        window.dataLayer.push({uid: window.uid});
    }
}

exports.onRouteUpdate = () => {
    if (document.querySelector('.instagram-media') !== null) {
        // Wait to ensure page is rendered first.
        setTimeout(() => {
            if (typeof gatsbyLoadInstagram !== `undefined` && typeof window.gatsbyLoadInstagram === `function`) {
                window.gatsbyLoadInstagram()
            }
        }, 0)
    }
}
