export const getUID = () => {
    var uid = getGUIDFromCookie('rga');
    if (!uid) {
        uid = generateUID();
        setCookie('rga', uid, 365);
    }

    return uid;
}

function getGUIDFromCookie(cookieName) {
    var value = document.cookie.match(`(^|;) ?${cookieName}=([^;]*)(;|$)`);
    return value ? value[2] : null;
}

function generateUID() {
    var str = '';
    for (var i = 0; i < 6; i++) {
        str += Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return str;
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + process.env.GATSBY_GTM_BASE_DOMAIN;
}
