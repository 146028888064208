// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-best-vacation-rental-software-js": () => import("./../../../src/pages/best-vacation-rental-software.js" /* webpackChunkName: "component---src-pages-best-vacation-rental-software-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-best-vacation-rental-software-js": () => import("./../../../src/pages/en/best-vacation-rental-software.js" /* webpackChunkName: "component---src-pages-en-best-vacation-rental-software-js" */),
  "component---src-pages-en-hotel-booking-engine-js": () => import("./../../../src/pages/en/hotel-booking-engine.js" /* webpackChunkName: "component---src-pages-en-hotel-booking-engine-js" */),
  "component---src-pages-en-hotel-channel-manager-js": () => import("./../../../src/pages/en/hotel-channel-manager.js" /* webpackChunkName: "component---src-pages-en-hotel-channel-manager-js" */),
  "component---src-pages-en-hotel-management-system-js": () => import("./../../../src/pages/en/hotel-management-system.js" /* webpackChunkName: "component---src-pages-en-hotel-management-system-js" */),
  "component---src-pages-en-hotel-property-management-system-js": () => import("./../../../src/pages/en/hotel-property-management-system.js" /* webpackChunkName: "component---src-pages-en-hotel-property-management-system-js" */),
  "component---src-pages-hotel-booking-engine-js": () => import("./../../../src/pages/hotel-booking-engine.js" /* webpackChunkName: "component---src-pages-hotel-booking-engine-js" */),
  "component---src-pages-hotel-channel-manager-js": () => import("./../../../src/pages/hotel-channel-manager.js" /* webpackChunkName: "component---src-pages-hotel-channel-manager-js" */),
  "component---src-pages-hotel-management-system-js": () => import("./../../../src/pages/hotel-management-system.js" /* webpackChunkName: "component---src-pages-hotel-management-system-js" */),
  "component---src-pages-hotel-property-management-system-js": () => import("./../../../src/pages/hotel-property-management-system.js" /* webpackChunkName: "component---src-pages-hotel-property-management-system-js" */),
  "component---src-pages-izjava-o-privatnosti-karijere-js": () => import("./../../../src/pages/izjava-o-privatnosti-karijere.js" /* webpackChunkName: "component---src-pages-izjava-o-privatnosti-karijere-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-booking-engine-js": () => import("./../../../src/templates/booking-engine.js" /* webpackChunkName: "component---src-templates-booking-engine-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-category-posts-js": () => import("./../../../src/templates/category-posts.js" /* webpackChunkName: "component---src-templates-category-posts-js" */),
  "component---src-templates-channel-manager-js": () => import("./../../../src/templates/channel-manager.js" /* webpackChunkName: "component---src-templates-channel-manager-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cookies-js": () => import("./../../../src/templates/cookies.js" /* webpackChunkName: "component---src-templates-cookies-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-hostel-js": () => import("./../../../src/templates/hostel.js" /* webpackChunkName: "component---src-templates-hostel-js" */),
  "component---src-templates-hotel-js": () => import("./../../../src/templates/hotel.js" /* webpackChunkName: "component---src-templates-hotel-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-integrations-js": () => import("./../../../src/templates/integrations.js" /* webpackChunkName: "component---src-templates-integrations-js" */),
  "component---src-templates-mobile-apps-js": () => import("./../../../src/templates/mobile-apps.js" /* webpackChunkName: "component---src-templates-mobile-apps-js" */),
  "component---src-templates-our-story-js": () => import("./../../../src/templates/our-story.js" /* webpackChunkName: "component---src-templates-our-story-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-property-management-system-js": () => import("./../../../src/templates/property-management-system.js" /* webpackChunkName: "component---src-templates-property-management-system-js" */),
  "component---src-templates-property-managers-js": () => import("./../../../src/templates/property-managers.js" /* webpackChunkName: "component---src-templates-property-managers-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-terms-js": () => import("./../../../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */),
  "component---src-templates-vacation-rentals-js": () => import("./../../../src/templates/vacation-rentals.js" /* webpackChunkName: "component---src-templates-vacation-rentals-js" */),
  "component---src-templates-why-rentlio-js": () => import("./../../../src/templates/why-rentlio.js" /* webpackChunkName: "component---src-templates-why-rentlio-js" */)
}

